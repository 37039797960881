<template>
    <div>
        <div class="changeType">
            <el-tabs type="border-card" @tab-click="getType" v-model="pageType">
                <el-tab-pane label="销售记录" name="1"></el-tab-pane>
                <el-tab-pane label="服务记录" name="2"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="flex justify-start" style="padding: 20px">
            <el-form :inline="true" :model="searchForm1" v-if="pageType == '1'">
                <el-form-item label="起始时间">
                    <el-date-picker
                        v-model="time1"
                        @change="getTime1"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="exportExcel">导出Excel</el-button>
                </el-form-item>
            </el-form>
            <el-form :inline="true" :model="searchForm2" v-if="pageType == '2'">
                <el-form-item label="技师名称">
                    <el-input v-model="searchForm2.technician_name" placeholder="技师名称"></el-input>
                </el-form-item>
                <el-form-item label="起始时间">
                    <el-date-picker
                        v-model="time2"
                        @change="getTime2"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="exportAccountList">导出当前页面商户统计</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData1" v-loading="loading" key="tableData1" v-if="pageType == '1'">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="merchant_name" label="门店名称"></el-table-column>
            <el-table-column prop="nickname" label="销售人员"></el-table-column>
            <el-table-column prop="product_name" label="产品"></el-table-column>
            <el-table-column prop="actual_fee" label="订单总金额"></el-table-column>
            <el-table-column prop="order_no" label="订单号"></el-table-column>
            <el-table-column prop="amount" label="提成金额"></el-table-column>
            <el-table-column prop="rule" label="提成规则"></el-table-column>
        </el-table>
        <el-table :data="tableData2" v-loading="loading" v-if="pageType == '2'">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="merchant_name" label="门店名称"></el-table-column>
            <el-table-column prop="technician_name" label="技师"></el-table-column>
            <el-table-column prop="serve_name" label="服务名称"></el-table-column>
            <el-table-column prop="order_no" label="订单号"></el-table-column>
            <el-table-column label="订单来源">
                <template slot-scope="scope">
                    <div v-if="scope.row.order_source == '1'">小程序</div>
                    <div v-if="scope.row.order_source == '2'">门店</div>
                </template>
            </el-table-column>
            <el-table-column label="服务类型">
                <template slot-scope="scope">
                    <div v-if="scope.row.server_type == '1'">到店</div>
                    <div v-if="scope.row.server_type == '2'">上门</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="text-center margin-top" v-if="pageType == '1'">
            <el-pagination
                @current-change="pageChange"
                :current-page="searchForm1.page"
                :page-sizes="pageSizes"
                :page-size="searchForm1.page_size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="searchForm1.total"
                @size-change="pageSizeChange"
            ></el-pagination>
        </div>
        <div class="text-center margin-top" v-if="pageType == '2'">
            <el-pagination
                @current-change="pageChange"
                :current-page="searchForm2.page"
                :page-sizes="pageSizes"
                :page-size="searchForm2.page_size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="searchForm2.total"
                @size-change="pageSizeChange"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false, // 表格加载状态
            pageType: '1',
            time1: [],
            time2: [],
            tableData1: [], // 数据
            tableData2: [], // 数据
            pageSizes: [10],
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
            },
            searchForm1: {
                start_date: undefined,
                end_date: undefined,
                page: 1, // 否 int 页码
                page_size: 10, // 否 int 页脚
                total: 0,
            },
            searchForm2: {
                start_date: undefined,
                end_date: undefined,
                technician_name: '',
                page: 1, // 否 int 页码
                page_size: 10, // 否 int 页脚
                total: 0,
            },
        };
    },
    mounted() {
        // 查询数据
        this.search();
    },
    methods: {
        getType() {
            console.log(this.pageType);
            this.search(1);
        },
        getTime1() {
            console.log(this.time1);
            if (this.time1 == null) {
                this.searchForm1.start_date = '';
                this.searchForm1.end_date = '';
            } else {
                this.searchForm1.start_date = this.time1[0];
                this.searchForm1.end_date = this.time1[1];
            }
        },
        getTime2() {
            console.log(this.time2);
            if (this.time2 == null) {
                this.searchForm2.start_date = '';
                this.searchForm2.end_date = '';
            } else {
                this.searchForm2.start_date = this.time2[0];
                this.searchForm2.end_date = this.time2[1];
            }
        },
        exportAccountList() {
            this.$api.statistics
                .exportSeverRecord({
                    technician_name: this.searchForm2.technician_name,
                    start_date: this.searchForm2.start_date,
                    end_date: this.searchForm2.end_date,
                    page: 1,
                    page_size: 9999,
                })
                .then((res) => {
                    console.log(res);
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: 'application/vnd.ms-excel',
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl);
                    a.setAttribute('href', objectUrl);
                    a.setAttribute('download', '技师服务记录.xls');
                    a.click();
                });
        },
        search(item) {
            if (item == 1) {
                this.searchForm1.page = 1;
                this.searchForm2.page = 1;
            }
            this.loading = true;

            // 将对象空字符串转为undefined
            if (this.pageType == '1') {
                let searchForm1 = this.$util.emptyToUndefined(this.searchForm1);
                this.$api.statistics.saleRecordRanking(searchForm1).then((res) => {
                    console.log(res);
                    this.searchForm1.total = res.data.count;
                    this.tableData1 = res.data.data;
                    this.loading = false;
                });
            } else {
                let searchForm2 = this.$util.emptyToUndefined(this.searchForm2);
                this.$api.statistics.severRecordRanking(searchForm2).then((res) => {
                    console.log(res);
                    this.searchForm2.total = res.data.total;
                    this.tableData2 = res.data.data;
                    this.loading = false;
                });
            }
        },
        pageSizeChange(size) {
            if (this.pageType == '1') {
                this.searchForm1.page_size = size;
            } else {
                this.searchForm2.page_size = size;
            }
            console.log(size);
        },
        pageChange(page) {
            if (this.pageType == '1') {
                this.searchForm1.page = page;
            } else {
                this.searchForm2.page = page;
            }
            this.search();
        },
        exportExcel() {
            this.$api.statistics
                .exportSaleRecord({
                    start_date: this.searchForm1.start_date,
                    end_date: this.searchForm1.end_date,
                })
                .then((res) => {
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: 'application/vnd.ms-excel',
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    a.setAttribute('href', objectUrl);
                    a.setAttribute('download', '技师销售记录.xls');
                    a.click();
                });
        },
    },
};
</script>

<style scoped lang="scss">
.cover {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
}
.changeType {
    padding: 20px;
    width: 196px;
}
.changeType /deep/ .el-tabs__content {
    padding: 0;
}
</style>
